import { Directus } from '@directus/sdk';
import { directusHelpers } from 'utils/directus-helpers';

export const directus = new Directus(process.env.REACT_APP_DIRECTUS_URL, {
  auth: {
    staticToken: 'vgl4OzP9KtCIUc6j4sf7sRp7NEx4U8I6'
  }
});

export const directusService = {};

directusService.login = (payload) => {
  return directus.auth.login({
    email: payload.email,
    password: payload.password,
    mode: 'json'
  });
};

directusService.logout = () => {
  // return directus.auth.logout(localStorage.getItem('auth_refresh_token'));
  localStorage.clear();
  // move to login page
  window.location.href = '/';
  // window.location.reload();
};

directusService.register = (payload, role) => {
  delete payload.submit;
  const users = directus.users;
  return users.createOne({
    ...payload,
    role: directusHelpers.getRole(role)
  });
};

directusService.getUserInfoWithToken = async (apiToken) => {
  try {
    const userData = await directus.users.me.read({
      fields: '*',
      headers: {
        Authorization: `Bearer ${apiToken}`
      }
    });
    const companyData = await directusService.getCollectionItems('Startup', '*', {
      owner_id: {
        id: {
          _eq: userData.id
        }
      }
    });
    return {
      user: userData,
      company: companyData[0] || []
    };
  } catch (err) {
    console.log('getUserInfoWithToken error', err);
  }
};



directusService.getCollectionItems = async (collectionName, fields = '*', filters = null, sort = null, options = null) => {
  const collection = directus.items(collectionName);
  try {
    const { data } = await collection.readByQuery(
      {
        limit: -1,
        fields: fields,
        filter: filters,
        sort: sort
      },
      options
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

directusService.createCollection = async (collectionName, payload) => {
  const collection = directus.items(collectionName);
  return await collection.createOne(payload, {});
};

directusService.resetAnswers = async (answerIds) => {

  const answers = directus.items('Answer');
  try {
    //deleteMany(ids: ID[], options?: ItemsOptions): Promise<void>;
    await answers.deleteMany(answerIds, {});
  } catch (error) {
    console.log(error);
  }

}

directusService.requestNewPassword = async (email) => {
  return await directus.auth.password.request(email);
};

directusService.checkIfUserExists = async (email) => {
  var users = directus.users;
  var foundUser = await users.readByQuery({
    filter: {
      email: {
        _eq: email
      }
    }
  });
  return foundUser.data.length > 0;
};

directusService.isAdmin = async () => {
  try {
    const currentUser = await directus.users.me.read();
    const role = await directus.roles.readOne(currentUser.role);
    return role.name === 'Admin_user';
  } catch (error) {
    console.log(error);
    return false;
  }
}